import React, { useState, useEffect } from "react";
import { string, bool } from "prop-types";
import { Link } from "gatsby";
import { styWrapper, styFlex } from "./styles";
import greetingServices from "../../services/greeting.services";
import _ from "lodash";

function ConfirmationSection({ guestName, codeLink }) {
  const [greeting, setGreeting] = useState([]);

  const getAllGreetings = async () => {
    const data = await greetingServices.getAllGreetings();
    console.log(data.docs);
    setGreeting(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(() => {
    getAllGreetings();
  }, []);

  // const filterName = _.filter(
  //   greeting,
  //   (data) => data.code !== "" && data.code === codeLink
  // );
  const filterCodeLink = _.filter(
    greeting,
    (data) => data?.authors?.toUpperCase() === guestName.toUpperCase()
  );
  const filtergreeting = _.filter(
    greeting,
    (data) =>
      data?.present === "true" &&
      data?.authors?.toUpperCase() === guestName.toUpperCase()
  );
  return (
    <div id="fh5co-started" className="fh5co-bg" css={styWrapper}>
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
            {filterCodeLink.length === 0 ? (
              <h2 className="main-font">{` Apakah kamu hadir, ${guestName}?`}</h2>
            ) : (
              <h2 className="main-font">{` Kami menunggu hadirmu ${guestName}`}</h2>
            )}
            <p>
              Atas kehadiran & do'a restu saudara/i, <br /> kami ucapkan terima
              kasih. Wassalamualaikum Warahmatullahi Wabarakatuh.
            </p>
          </div>
        </div>
        <div className="row" css={styFlex}>
          {/* <div className="col-md-3">
            <Link to={`e-ticket?${codeLink}`}>
              <button className="btn btn-default btn-block">Konfirmasi</button>
            </Link>
          </div> */}
          {filterCodeLink.length === 0 && (
            <div className="col-md-4">
              <Link to={`e-greetingcard?to=${guestName}&code=${codeLink}`}>
                <button className="btn btn-default btn-block">
                  Konfirmasi & Kirim Ucapan
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ConfirmationSection.propTypes = {
  codeLink: string.isRequired,
  isInvitation: bool.isRequired,
  guestName: string.isRequired,
};

export default React.memo(ConfirmationSection);
