import React, { Fragment } from "react";
import { GOOGLE_MAPS_LINK } from "@/constants";
import { styWrapper } from "./styles";
import { Link } from "gatsby";

function LocationSection() {
  const showInMapClicked = () => {
    window.open(
      "https://www.google.com/maps/dir//Gedung+Serbaguna+Gadungan+,+Wedi+7H2M%2BM89+Dusun+I,+Gadungan+Kec.+Wedi,+Kabupaten+Klaten,+Jawa+Tengah+57461/@-7.7482451,110.5483391,13z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x2e7a455f2287ea61:0x2a47afbf578e5504!2m2!1d110.5833585!2d-7.7483316"
    );
  };

  return (
    <Fragment>
      <div
        id="fh5co-couple-story"
        className="fh5co-section-gray"
        css={styWrapper}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
              <h2 className="main-font">Lokasi Acara</h2>
              <p className="sub-title">
                <a
                  href={GOOGLE_MAPS_LINK}
                  title="Click untuk melihat peta di Google Maps"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#828282" }}
                >
                  <strong>Gedung Gadungan Wedi Klaten</strong>
                </a>{" "}
                <br />
                Dusun I,Gadungan, Wedi,Klaten
              </p>
              <div className="col-md-4 col-md-offset-4 text-center fh5co-heading animate-box">
                <button
                  onClick={showInMapClicked}
                  className="btn btn-default btn-block"
                  style={{
                    backgroundColor: "rgba(241, 78, 149, 0.8)",
                    color: "white",
                  }}
                >
                  Lokasi
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.390575729504!2d110.58116981535683!3d-7.748331594415429!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a455f2287ea61%3A0x2a47afbf578e5504!2sGedung%20Serbaguna%20Gadungan%20%2C%20Wedi!5e0!3m2!1sen!2sid!4v1660192939534!5m2!1sen!2sid"
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: "0" }}
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
                title="Google Maps - Janu & Indra Wedding Party"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default React.memo(LocationSection);
