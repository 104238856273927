import React, { Fragment } from "react";
import { bool } from "prop-types";

import Bride from "@assets/images/poto/poto-wj.webp";
import Groom from "@assets/images/poto/poto-indra.webp";

import { styWrapper } from "./styles";

function HelloSection({ isInvitation }) {
  const finalSubtitle = isInvitation
    ? "Minggu, 25 September 2022"
    : "Minggu, 25 September 2022";

  return (
    <Fragment>
      <div id="fh5co-couple" css={styWrapper}>
        <div className="overlay" />
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2 className="main-font">Assalamualaikum Wr. Wb</h2>
              <h3 className="sub-title hs">{finalSubtitle}</h3>
              <p className="info">
                Dengan memohon Rahmat dan Ridho Illahi, teriring niat
                menjalankan Sunnah Rasulullah ﷺ untuk membentuk rumah tangga
                yang Sakinah, Mawaddah wa Rahmah, kami mohon do'a agar
                senantiasa diberikan kelancaran dan keberkahan.
              </p>
            </div>
          </div>
          <div className="couple-wrap">
            <div className="couple-half">
              <div className="groom">
                <img
                  src={Bride}
                  alt="groom"
                  className="img-responsive"
                  loading="lazy"
                />
              </div>
              <div className="desc-groom">
                <h3 className="main-font">Wahyu Januari, Amd.Kom </h3>
                <p className="parent-name parent-name__top" style={{color:'black'}}>
                  Putri Bapak Suharno <br />& Ibu Sugiyem
                </p>
              </div>
            </div>
            <p className="heart text-center">
              <i className="icon-heart2"></i>
            </p>
            <div className="and-love">
              <i>&</i>
            </div>
            <div className="couple-half">
              <div className="bride">
                <img
                  src={Groom}
                  alt="groom"
                  className="img-responsive"
                  loading="lazy"
                />
              </div>
              <div className="desc-bride">
                <h3 className="main-font">Imanindra Permana, S.Kom.</h3>
                <p className="parent-name text-black" style={{color:'black'}}>
                  Putra Bapak Siman Riyadi <br />& Ibu Maryatun
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {!isInvitation ? (
        <div id="fh5co-couple" className="fh5co-section-gray" css={styWrapper}>
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                <h2 className="main-font">Seputar Covid-19</h2>
                <p className="info">
                  Berkaitan dengan kondisi pandemik saat ini, keberlangsungan
                  acara kami mengacu pada pedoman Kementerian Kesehatan, yaitu
                  memberlakukan pembatasan jarak dan pembatasan jumlah tamu
                  undangan.
                </p>
                <p className="info">
                  Maka dari itu, dengan segala kerendahan hati, kami mohon maaf
                  jika belum bisa mengundang saudara/i dihari bahagia kami.
                  Sekiranya do'a dari saudara/i sudah lebih dari cukup bagi
                  kami. Semoga kita semua selalu dalam lindungan Allah SWT.
                  Aamiin ya rabbal allamiin..
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null} */}
    </Fragment>
  );
}

HelloSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default HelloSection;
