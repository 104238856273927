import React, { Fragment } from "react";
import { bool } from "prop-types";

function FooterSection({ isInvitation }) {
  return (
    <Fragment>
      <footer id="fh5co-footer" role="contentinfo">
        <div className="container">
          <div className="row copyright">
            <div className="col-md-12 text-center">
              <p>
                <small className="block">
                  &copy; 2022 Janu & Indra Wedding. All Rights Reserved.
                </small>
                <small className="block">
                  Covid-19 Icon by{" "}
                  <a
                    href="https://www.flaticon.com/packs/covid-protection-measures-5"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Flat Icon - Frepik
                  </a>
                </small>
                <small className="block">
                  Song by{" "}
                  <a
                    href="https://www.youtube.com/watch?v=pmajAl_0yjg"
                    target="_blank"
                    rel="noreferrer"
                  >
                   Tyok Satrio - Ada Untukmu
                  </a>
                </small>
                <small className="block">
                  Original Template from{" "}
                  <a
                    href="http://freehtml5.co/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    FREEHTML5.co
                  </a>
                </small>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}

FooterSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default React.memo(FooterSection);
