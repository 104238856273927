import React from "react";
import { styWrapperItem, styWithAnimation } from "./styles";
import Icon1 from "../../assets/images/avatars/Asset1.webp";
import Icon2 from "../../assets/images/avatars/Asset2.webp";
import Icon3 from "../../assets/images/avatars/Asset3.webp";
import Icon4 from "../../assets/images/avatars/Asset4.webp";
import Icon5 from "../../assets/images/avatars/Asset5.webp";
import Icon6 from "../../assets/images/avatars/Asset6.webp";

function WishesItem({ authors, infoName, greeting, avatar_id, isActive }) {
  if (!isActive) return null;

  const renderItem = () => {
    return (
      <div className="item" css={styWrapperItem}>
        <div
          className={`testimony-slide text-center ${
            isActive ? "active" : "hide"
          }`}
        >
          <figure className="text-center">
            {/* <UserAvatar size="100" name={authors} /> */}
            {avatar_id === `1` && (
              <img
                src={Icon1}
                style={{ width: 100, height: 100 }}
                alt="avatar"
              />
            )}
            {avatar_id === `2` && (
              <img
                src={Icon2}
                style={{ width: 100, height: 100 }}
                alt="avatar"
              />
            )}
            {avatar_id === `3` && (
              <img
                src={Icon3}
                style={{ width: 100, height: 100 }}
                alt="avatar"
              />
            )}
            {avatar_id === `4` && (
              <img
                src={Icon4}
                style={{ width: 100, height: 100 }}
                alt="avatar"
              />
            )}
            {avatar_id === `5` && (
              <img
                src={Icon5}
                style={{ width: 100, height: 100 }}
                alt="avatar"
              />
            )}
            {avatar_id === `6` && (
              <img
                src={Icon6}
                style={{ width: 100, height: 100 }}
                alt="avatar"
              />
            )}
          </figure>
          <h4>{authors}</h4>
          <span className="infoName">{infoName}</span>
          <blockquote>
            <p className="description">{greeting}</p>
          </blockquote>
        </div>
      </div>
    );
  };

  return <div css={styWithAnimation(isActive)}>{renderItem()}</div>;
}

WishesItem.propTypes = {
  // image: string.isRequired,
  // name: string.isRequired,
  // infoName: string.isRequired,
  // description: string.isRequired,
  // isActive: bool.isRequired,
};

export default React.memo(WishesItem);
