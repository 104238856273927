import React from "react";

import IconMask from "./assets/face-mask.png";
import IconSocialDistancing from "./assets/parents.png";
import IconSneeze from "./assets/sneeze.png";
import IconNoTouch from "./assets/no-touch.png";
import IconWash from "./assets/washing-hands.png";
import SectionBox from "./SectionBox";

function CovidSection() {
  return (
    <div id="fh5co-couple">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <h2 className="main-font">Protokol Kesehatan Covid-19</h2>
            <p className="info" style={{ marginBottom: "-16px" }}>
              Demi mendukung kesehatan bersama alangkah baiknya para tamu yang
              akan hadir memenuhi protokol kesehatan sebagai berikut :
            </p>
          </div>
        </div>
        {/* BOX INFO */}
        <div className="row">
          <div className="col-md-12">
            <SectionBox icon={IconMask} text="Wajib menggunakan masker" />
            <SectionBox
              icon={IconWash}
              text="Selalu mencuci tangan dengan sabun"
            />
            <SectionBox
              icon={IconSneeze}
              text="Etika menutup mulut dan hidung ketika bersin"
            />
            <SectionBox
              icon={IconNoTouch}
              text="Jangan Menyentuh muka dengan tangan yang belum bersih"
            />
            <SectionBox icon={IconSocialDistancing} text="Selalu jaga jarak" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CovidSection;
